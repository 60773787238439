/* @media (prefers-reduced-motion: no-preference) { */
    .waiting-icon {
        animation: heartbeat infinite 1.0s linear;
    }
/* } */

.waiting-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 10em;
}

.waiting-icon {
    width: 5em;
}

.waiting-icon {
    height: 5em;
}

.waiting-box {
    height: 15em;
}

.selected-stream-card {
    background-color: #4c6e89 !important;
    transition: all 2s ease;
}

.thumbnail {
  height: 150px;
  background: rgba(0, 0, 0, 0.15);
  position: relative;
}
