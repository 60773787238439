body {
  height: 100vh;
  width: 100vw;
  /* overflow: hidden; */
}

.App {
  margin: 0;
  text-align: center;
  background-color: #000000;
  color: #f0f;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-clip: border-box;
}

.App.idle, .App.paused, .App.error {
  background: url(poster-notext.png) no-repeat bottom;
  background-color: #000000;
  background-size: 100% auto;
}

@media (max-aspect-ratio: 16/9) {
  .App {
    background-size: contain;
  }
}

.invisible-click-catcher {
  cursor: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  background: transparent;
}

.invisible-click-catcher.mousing {
  cursor: auto;
}

.invisible-menu-opener {
  background: #353535;
  background: linear-gradient(180deg, rgba(53,53,53,.4) 0%, rgba(53,53,53,0) 100%);
  color: white;
  /*box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%);*/
  position: absolute;
  top: -3rem;
  transition: top 250ms ease 0s;
  left: 0;
  height: 3rem;
  width: 100vw;
  z-index: 1001;
  cursor: auto;
}

.invisible-menu-opener.mousing {
  top: 0vh;
}

div.state-overlay {
  visibility: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
}

.loading div.state-overlay, .stalled div.state-overlay {
  visibility: visible;
}

.loading div.state-overlay {
  height: 40vh;
}

.loading div.state-overlay .loading-icon {
  width: 100%; 
  height: 25vh;
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, .5));
}

.stalled div.state-overlay {
  height: 10vh;
}

.stalled div.state-overlay .loading-icon {
  display: block;
  height: 5vh;
  filter: drop-shadow(0 0 10px rgb(255, 255, 255));
  margin: 1rem;
}

/* @media (prefers-reduced-motion: no-preference) { */
.loading-icon {
    animation: heartbeat infinite .6s linear;
}
/* } */

.idle .ovenplayer, .error .ovenplayer {
  visibility: hidden;
}

.error-overlay {
  visibility: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 30vh;
  background: rgba(25, 25, 25, 0.9);
}

.error .error-overlay {
  visibility: visible;
}

div.cast-overlay {
  visibility: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 10em;
  width: 100vw;
  color: #aaa;
}

.casting .cast-overlay {
  visibility: visible;
}

.ovenplayer {
  height: 100vh;
  width: 100%;
}

.paused .ovenplayer {
  visibility: hidden;
}

.op-message-box .op-message-container, .op-spinner-container {
  top: 5% !important;
}
.op-live-badge {
  display: none !important;
}
.op-setting-item[op-panel-type=speed] {
  display: none !important;
}

/* .stream-selector-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  border: .5px solid #999;
  border-radius: 50px;
  padding: 50px;
  background-color: #666;
  box-shadow: 0px 0px 50px 5px #999;
} */

.op-ui {
  visibility: hidden;
}

.error .op-ui {
  visibility: visible;
}

.SnackbarItem-variantWarning a {
  text-decoration: none;
  font-weight: bold;
  color: #750039 !important;
}

.MuiDrawer-paper {
  background-color: rgba(0,0,0,0.8) !important;
}

.player-container.loading-content {
  visibility: hidden;
}


@keyframes heartbeat
{
  0%
  {
    transform: scale( .75 );
  }
  20%
  {
    transform: scale( 1 );
  }
  40%
  {
    transform: scale( .85 );
  }
  60%
  {
    transform: scale( 1 );
  }
  80%
  {
    transform: scale( .8 );
  }
  100%
  {
    transform: scale( .75 );
  }
}

div.stream-selector-and-selection-options {
  min-height: 365px;
  display: flex;
  flex-direction: column;
}

.placeholder-video {
  video {
    object-fit: cover;
  }
}

.mainVideoContainer {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.mainVideoContainer.crtFilter .player-container {
  filter: url(#glow)/* url(#crt-sphere)*/;
}

.crtFilter .crtOverlay {
  pointer-events: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0,0,0,.25) 0%,rgba(0,0,0,0) 30%,rgba(0,0,0,0) 70%,rgba(0,0,0,.25) 100%);
  background-size: 100% 4px;
}